import { EnvType } from './environments/type';
import {enableProdMode, importProvidersFrom} from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from '@app/app';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { en_US, NZ_DATE_LOCALE, NZ_I18N } from 'ng-zorro-antd/i18n';
import { enUS } from 'date-fns/locale';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { WebLibModule } from '@wearewarp/ng-web';
import webLibConfig from '@const/webLibConfig';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from '@app/app.routing';


if (environment.type == EnvType.prod || environment.type == EnvType.stg) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_DATE_LOCALE, useValue: enUS },
    importProvidersFrom(WebLibModule.forRoot(webLibConfig)),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(APP_ROUTES)
  ]
})