import { BaseComponent } from "@abstract/BaseComponent";
import { Directive, Input } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { UIHelper } from "@services/UIHelper";
import { FormUtil } from "@services/form-util";
import { InputHelper } from "@services/input-helper";
import { Log } from "@services/log";
import { Utils } from "@services/utils";

@Directive()
export abstract class BaseFormItem<T = any> extends BaseComponent {
  private _model: T;
  @Input() set model(value) {
    this._model = value;
  }
  get model() {
    return this._model;
  }
  isEditing: boolean = false;
  formInput: FormGroup;
  fileToUpload: {[key: string]: File} = {}
  protected formGroupDeclaration: FormGroupDeclaration = {};
  private _formInputKeys = [];
  get formInputKeys(): Array<string> {
    return this._formInputKeys;
  }

  get shouldCreateFormImmediately() { return true}

  ngOnInit() {
    super.ngOnInit();
    for (let key of Object.keys(this.formGroupDeclaration)) {
      let d = this.formGroupDeclaration[key];
      if (d.type == 'uploadFile') {
        d.isChanged = this.isChangedFile.bind(this, key);
        if (d.required) {
          FormUtil.addValidator(d, this.requireAttachFile.bind(this, key));
        }
      }
    }
    if (this.shouldCreateFormImmediately) {
      this.createFormInput(this.model);
      this.setEnableFormGroup(true);  // make sure all readOnly fields will be disabled after created.
    }
  }

  protected reset() {
    this.fileToUpload = {};
    if (this.model) {
      for (let key of this.formInputKeys) {
        if (this.formGroupDeclaration[key].type == 'uploadFile' && this.model[key] && this.model[key].deleted) {
          delete this.model[key].deleted;
        }
      }
    }
  }

  // Gọi hàm này trước khi bind model vào form
  // Mục đích để tạo 1 số trường dữ liệu mà API ko trả về
  protected beforeBindModel(model): any {
    return model;
  }

  protected afterBindModel() {}

  protected bindDataModel(model) {
    FormUtil.bindData(this.formInput, this.beforeBindModel(model), this.formGroupDeclaration);
    this.afterBindModel();
    this.setEnableFormGroup(false);
  }

  public formData_JSON(isCreateNew: boolean): T {
    return this.getFormData_JSON(isCreateNew);
  }
  
  protected getFormData_JSON(isCreateNew: boolean): T {
    let originalData = isCreateNew ? undefined : this.model;
    return FormUtil.getFormGroupData(this.formInput, this.formGroupDeclaration, originalData);
  }
  
  protected getFormData(isCreateNew: boolean): T|FormData {
    let jsonData = this.getFormData_JSON(isCreateNew);
    let fileKeys = Object.keys(this.fileToUpload);
    if (fileKeys.length > 0) {
      let formData = new FormData();
      for (let key of fileKeys) {
        if (this.fileToUpload[key] instanceof File) {
          formData.append(key, this.fileToUpload[key], this.fileToUpload[key].name);
        } else if (this.model && this.model[key] && this.model[key].deleted) {
          jsonData[key] = null;
        }
      }
      formData.append('params', JSON.stringify(jsonData));
      return formData;
    } else {
      return jsonData;
    }
  }
  
  get isCreateNew(): boolean {
    return !this.model || !(<any>this.model)._id;
  }

  get formGroupError() {
    return FormUtil.validateFormGroup(this.formInput);
  }
  
  get needUpdate() {
    if (!this.formInput || !Utils.isObjectNotEmpty(this.formInput.controls)) return false;
    let err = this.formGroupError;
    if (err) {
      return false;   // nếu có lỗi thì ko update gì cả
    }
    // Tạo mới thì chỉ cần check đến đây là đủ
    if (this.isCreateNew) {
      // Nếu là tạo mới, mà form input ko có lỗi gì thì cho save
      return true;
    }
    // Edit thì phải so sánh với các giá trị cũ xem có khác nhau ko
    if (!this.model) {
      return false;
    }
    return this.isFormDataChanged();
  }
  
  protected isFormDataChanged() {
    return FormUtil.isFormGroupChanged(this.formInput, this.model, this.formGroupDeclaration);
  }
  
  protected setEnableFormGroup(enabled: boolean) {
    FormUtil.setEnableFormGroup(this.formInput, this.formGroupDeclaration, enabled);
  }
  
  protected createFormInput(bindData = undefined) {
    Log.d('createFormInput bindData: ', bindData, ' -- formGroupDeclaration: ', this.formGroupDeclaration);
    if (bindData) {
      this.formInput = FormUtil.createFormGroup(this.formGroupDeclaration, this.beforeBindModel(bindData));
      this.afterBindModel();
    } else {
      this.formInput = FormUtil.createFormGroup(this.formGroupDeclaration);
    }
    this._formInputKeys = Object.keys(this.formInput.controls);
  }
  
  protected addItemToFormGroup(itemKey: string, declaration: FormControlDeclaration, bindData = undefined) {
    if (this.formGroupDeclaration[itemKey]) {
      throw Error(`${itemKey} has already existed in formGroupDeclaration`);
    }
    this.formGroupDeclaration[itemKey] = declaration;
    FormUtil.addItemToFormGroup(this.formInput, itemKey, declaration, bindData);
    this._formInputKeys.push(itemKey);
  }
  
  protected removeFormGroupItem(itemKey: string) {
    this.formInput.removeControl(itemKey);
    this.formGroupDeclaration[itemKey] = undefined;
    this._formInputKeys = this._formInputKeys.filter(it => it != itemKey);
  }
  
  protected removeFormGroupItems(itemKeys: Array<string>) {
    for (let itemKey of itemKeys) {
      this.formInput.removeControl(itemKey);
      this.formGroupDeclaration[itemKey] = undefined;
    }
    this._formInputKeys = Object.keys(this.formInput.controls);
  }
  
  protected isChangedFile(key) {
    if (this.fileToUpload[key] instanceof File) {
      return true;
    }
    if (this.model[key] && this.model[key].deleted) {
      return true;
    }
    return false;
  }
  
  protected requireAttachFile(key) {
    if (this.fileToUpload[key] instanceof File) {
      return null;
    }
    if (this.model && this.model[key]) {
      return null;
    }
    return {require: true};
  }
  
  onFileSelected(key, files) {
    this.fileToUpload[key] = files[0];
    this.formInput.get(key)?.updateValueAndValidity();
  }
  
  hasAttachedFile(key: string) {
    return !!this.fileToUpload[key];
  }
  
  delFile(key, inputElement: HTMLInputElement) {
    if (!this.fileToUpload[key]) return;
    UIHelper.confirmDeletion({message: `Delete file ${this.fileToUpload[key].name}?`, txtBtnOk: 'Delete', fnOk: () => {
      this.fileToUpload[key] = undefined;
      this.formInput.get(key)?.updateValueAndValidity();
      inputElement.value = '';
    }});
  }
  
  delAttachedFile(key) {
    if (!this.model[key].deleted) {
      UIHelper.confirmDeletion({message: `Delete file ${this.model[key].name}?`, txtBtnOk: 'Delete', fnOk: () => {
        this.model[key].deleted = true;
      }});
    } else {
      this.model[key].deleted = false;
    }
  }

  // key can be like this: 'customer.warehouses[2].address.city'
  getChildFormInfoByKey(key: string): {formControl: AbstractControl, declaration: FormControlDeclaration} {
    let arr = key.split('.');
    let currentControl: AbstractControl = this.formInput;
    let currentDeclaration: FormGroupDeclaration|FormControlDeclaration = this.formGroupDeclaration;
    for (let i = 0; i< arr.length; i++) {
      if (!currentControl) {
        return null;
      }
      let match = arr[i].match(/\[[0-9]+\]$/);
      let subKey = arr[i];
      if (match && match[0]) {
        subKey = arr[i].substring(0, match.index);
        let index = Number(match[0].replace(/[^0-9]/g, ''));
        currentControl = (<FormArray>currentControl.get(subKey)).at(index);
      } else {
        currentControl = currentControl.get(subKey);
      }
      currentDeclaration = currentDeclaration[subKey];
      if (currentControl instanceof FormGroup || currentControl instanceof FormArray) {
        currentDeclaration = currentDeclaration.childItem;
      }
    }
    return {formControl: <FormControl>currentControl, declaration: <FormControlDeclaration>currentDeclaration}
  }

  setItemValue(key: string, value) {
    let item = this.getChildFormInfoByKey(key);
    if (!item) {
      return;
    }
    FormUtil.bindData(item.formControl, value, item.declaration);
  }
  
  getItemValue(key: string): any {
    let item = this.getChildFormInfoByKey(key);
    if (!item) {
      return null;
    }
    return FormUtil.getFormItemData(item.formControl, item.declaration);
  }

  isDate(key: string): boolean {
    return FormUtil.getTypeForKey(key, this.formGroupDeclaration) == 'date';
  }
  
  isUploadFile(key: string): boolean {
    return FormUtil.getTypeForKey(key, this.formGroupDeclaration) == 'uploadFile';
  }
  
  isString(key: string): boolean {
    return FormUtil.getTypeForKey(key, this.formGroupDeclaration) == 'string';
  }
  
  isBool(key: string): boolean {
    return FormUtil.getTypeForKey(key, this.formGroupDeclaration) == 'boolean';
  }
  
  isFormGroup(key: string): boolean {
    return FormUtil.getTypeForKey(key, this.formGroupDeclaration) == 'formGroup';
  }
  
  isRequired(key: string): boolean {
    return FormUtil.isRequired(key, this.formGroupDeclaration);
  }

  isHidden(key: string): boolean {
    return FormUtil.isHidden(key, this.formGroupDeclaration);
  }

  isReadOnly(key: string): boolean {
    return FormUtil.isReadOnly(key, this.formGroupDeclaration);
  }

  isMultiline(key: string): boolean {
    return FormUtil.isMultiline(key, this.formGroupDeclaration);
  }
  
  getLabel(key: string): string {
    return FormUtil.getLabelForKey(key, this.formGroupDeclaration);
  }
  
  getPlaceHolder(key: string): string {
    return FormUtil.getItemByKey(key, this.formGroupDeclaration)?.placeHolder || '';
  }
  
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types
  getInputType(key: string): string {
    return FormUtil.getItemByKey(key, this.formGroupDeclaration).inputType || 'text';
  }

  fullKey(...args): string {
    return args.join('.');
  }

  getFormArray(key: string): FormArray {
    return <FormArray>this.formInput.get(key);
  }
  
  getArrayControls(key: string): Array<AbstractControl> {
    let f = this.getFormArray(key);
    if (f) return f.controls || [];
    return [];
  }
  
  addItemToFormArray(key: string, bindData = null) {
    let fa = this.getFormArray(key);
    let declaration = this.formGroupDeclaration[key];
    let childItem = declaration.childItem;
    if (childItem) {
      let fg = FormUtil.createFormGroup(<FormGroupDeclaration>childItem, bindData);
      fa.push(fg);
      FormUtil.setEnableFormGroup(fg, <FormGroupDeclaration>childItem, true);
    } else {
      let fc = new FormControl();
      fa.push(fc);
    }
  }
  
  removeItemInFormArray(key: string, index: number) {
    let fa = this.getFormArray(key);
    fa.removeAt(index);
  }
  
  getChildItemKeys(key: string) {
    let child = this.formGroupDeclaration[key]?.childItem;
    if (child) return Object.keys(child);
    return [];
  }

  // event.inputType: insertFromPaste | insertText | historyUndo | deleteContentBackward
  // handle text changed by copy paste
  onInputChanged(event, key) {
    if (key == 'phone') {
      InputHelper.handleInputChangePhone(event, <FormControl>this.formInput.get(key));
    }
    if(key === "otp") {
      InputHelper.handleInputChangeNumberOnly(
        event,
        <FormControl>this.formInput.get(key)
      );
    }
  }

  onInputKeyPress(event: KeyboardEvent, key) {
    if (["phone", "otp"].includes(key)) {
      // Allow number only
      return InputHelper.handleInputKeyPressNumberOnly(event);
    }
    return true;
  }

}