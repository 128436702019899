import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { DateUtil } from "./date-util";
import { Utils } from "./utils";
import { InputHelper } from "./input-helper";

interface ValidateFormOptions {
  includeErrorKey?: boolean,
  markFormError?: boolean,
}

export class FormUtil {

  public static type(d: FormControlDeclaration): FormDataType {
    return d.type || 'string';
  }

  public static getFormItemData(formItem: AbstractControl, declaration: FormControlDeclaration): any {
    let value = formItem.value;
    if (declaration.getValue) {
      return declaration.getValue(value);
    } else if (typeof value === 'string') {
      if (declaration.type == 'number') {
        if (value.trim() == '') {
          return null;  // consider an empty string to be null value
        } else {
          let n = Number(value);
          if (isNaN(n)) {
            return null;  // consider NaN to be null value
          }
          return n;
        }
      }
      if (!declaration.notTrim) {
        return value.trim();
      }
    } else if (value instanceof Date) {
      return value.toISOString();
    } else {
      return value;
    }
  }

  public static getFormArrayData(formArray: FormArray, declaration: FormGroupDeclaration|FormControlDeclaration): Array<any> {
    let arr = [];
    for (let i = 0; i < formArray.length; i++) {
      let f = formArray.at(i);
      let item;
      if (f instanceof FormArray) {
        item = this.getFormArrayData(f, declaration);
      } else if (f instanceof FormGroup) {
        item = this.getFormGroupData(f, <FormGroupDeclaration>declaration);
      } else {
        item = this.getFormItemData(<FormControl>f, <FormControlDeclaration>declaration);
      }
      arr.push(item);
    }
    return arr;
  }

  /**
   * @param formGroup 
   * @param declaration 
   * @param originalData to compare with form data in case of updating, only get form data if there is a change.
   */
  public static getFormGroupData(formGroup: FormGroup, declaration: FormGroupDeclaration, originalData?: any): any {
    let keys = Object.keys(formGroup.controls);
    let jsonData = {};
    for (let key of keys) {
      if (declaration[key].readOnly && !declaration[key].submitReadOnly) {
        // skip readOnly fields
        continue;
      }
      let f = formGroup.get(key);
      let data;
      if (f instanceof FormArray) {
        if (originalData && !this.isFormArrayChanged(f, originalData[key], declaration[key].childItem)) {
          continue;
        }
        data = this.getFormArrayData(f, declaration[key].childItem);
      } else if (f instanceof FormGroup) {
        if (originalData && !this.isFormGroupChanged(f, originalData[key], <FormGroupDeclaration>declaration[key].childItem)) {
          continue;
        }
        data = this.getFormGroupData(f, <FormGroupDeclaration>declaration[key].childItem);
      } else {
        if (originalData && !this.isFormItemChanged(<FormControl>f, originalData[key], declaration[key])) {
          continue;
        }
        if (declaration[key].type == 'uploadFile') {
          // uploadFile is the special & rare case, specific class should handle FormData
          continue;
        }
        data = this.getFormItemData(<FormControl>f, declaration[key]);
      }
      if (declaration[key].notAcceptEmpty && this.isEmpty(data)) {
        // skip empty string/array/object
        continue;
      }
      if (declaration[key].notAcceptNull && (data === null || data === undefined)) {
        // skip null/undefined value
        continue;
      }
      jsonData[key] = data;
    }
    return jsonData;
  }

  public static isEmpty(data): boolean {
    if (data === null || data === undefined) {
      return true;
    }
    if (typeof data == 'string') {
      return data.length == 0;
    } else if (data.constructor.name === "Object") {
      return Object.keys(data).length == 0
    } else if (Array.isArray(data)) {
      return data.length == 0;
    }
    return false;
  }
  
  public static getLabelForKey(key: string, declaration: FormGroupDeclaration): string {
    let item = this.getItemByKey(key, declaration);
    if (!item) return '';
    return item.label;
  }

  public static getTypeForKey(key: string, declaration: FormGroupDeclaration): FormDataType {
    let item = this.getItemByKey(key, declaration);
    if (!item) return null;
    return item.type || 'string';
  }

  /**
   * @param key using dot separated string for nested group/array (e.g, vehicles.trucks.weight)
   * @param declaration 
   */
  public static getItemByKey(key: string, declaration: FormGroupDeclaration): FormControlDeclaration {
    let arr = key.split('.');
    let item: FormControlDeclaration;
    let group: FormGroupDeclaration = declaration;
    for (let i = 0; i< arr.length; i++) {
      let key = arr[i];
      if (i == arr.length - 1) {
        item = group[key];
      } else {
        group = <FormGroupDeclaration>group[key].childItem;
      }
    }
    return item;
  }

  public static bindData(formControl: AbstractControl, model: any, declaration: FormGroupDeclaration|FormControlDeclaration) {
    if (formControl instanceof FormControl) {
      return this.bindDataItem(formControl, <FormControlDeclaration>declaration, model);
    } else if (formControl instanceof FormArray) {
      return this.bindDataArray(formControl, <FormGroupDeclaration>declaration, <Array<any>>model);
    }
    let formGroup = <FormGroup>formControl;
    let keys = Object.keys(formGroup.controls);
    for (let key of keys) {
      let value = model[key];
      if (value === undefined || value === null) {
        continue;
      }
      let formControl = formGroup.get(key);
      if (formControl instanceof FormArray) {
        formControl.clear();
        if (model[key]) {
          if (!Array.isArray(model[key])) {
            throw Error(`model[${key}] must be an array`);
          }
          for (let item of model[key]) {
            if (typeof item === 'object') {
              let fg = this.createFormGroup(<FormGroupDeclaration>declaration[key].childItem, item);
              formControl.push(fg);
            } else {
              let fc = new FormControl();
              fc.setValue(item);
              formControl.push(fc);
            }
          }
        }
      } else if (formControl instanceof FormGroup) {
        if (model[key]) {
          if (typeof model[key] !== 'object') {
            throw Error(`model[${key}] must be an object`);
          }
          this.bindData(formControl, model[key], <FormGroupDeclaration>declaration[key].childItem);
        }
      } else {
        this.bindDataItem(<FormControl>formControl, declaration[key], value);
      }
    }
  }

  public static formatValue(declaration: FormControlDeclaration, value): string {
    if (declaration.formatValue) {
      if (typeof value === 'string') {
        value = declaration.formatValue(value);
      } else if (typeof value === 'number') {
        value = declaration.formatValue(String(value));
      } else {
        value = declaration.formatValue(value);
      }
    } else if (declaration.inputType == 'tel') {
      value = InputHelper.formatPhone(value);
    } else {
      let type = this.type(declaration);
      switch (type) {
        case 'date':
        case 'dateTime':
        case 'time':
          value = DateUtil.isoDate(value);
          break;
      }
    }
    return value;
  }

  public static bindDataArray(formArray: FormArray, childItemDeclaration: FormGroupDeclaration, dataAray: Array<any>) {
    if (formArray.length > dataAray.length) {
      for (let i = formArray.length - 1; i >= dataAray.length; i--) {
        formArray.removeAt(i);
      }
    }
    for (let i = 0; i < dataAray.length; i++) {
      if (formArray.length > i) {
        FormUtil.bindData(formArray.at(i), dataAray[i], childItemDeclaration);
      } else {
        let fg = FormUtil.createFormGroup(childItemDeclaration, dataAray[i]);
        formArray.push(fg);
        FormUtil.setEnableFormGroup(fg, childItemDeclaration, true);
      }
    }
  }

  public static bindDataItem(formControl: FormControl, declaration: FormControlDeclaration, dataItem: any) {
    let value = dataItem != null ? this.formatValue(declaration, dataItem) : dataItem;
    formControl.setValue(value, {emitEvent: false});
  }
  
  public static addValidator(declaration: FormControlDeclaration, fn: Function) {
    let validators = [];
    let v = declaration.validators;
    if (v) {
      if (Array.isArray(v)) {
        validators = <any[]>v;
      } else {
        validators.push(v);
      }
    }
    validators.push(fn);
    declaration.validators = validators;
  }

  private static createValidators(declaration?: FormControlDeclaration) {
    let validators = [];
    let v = declaration?.validators;
    if (v) {
      if (Array.isArray(v)) {
        validators = <any[]>v;
      } else {
        validators.push(v);
      }
    }
    if (declaration?.required) {
      validators.push(Validators.required);
    }
    return validators;
  }
  
  public static addItemToFormGroup(formGroup: FormGroup, itemKey: string, declaration: FormControlDeclaration, bindData: any = undefined) {
    let formControl: AbstractControl;
    switch (declaration.type) {
      case 'formArray':
        formControl = this.createFormArray(declaration, bindData);
        formGroup.addControl(itemKey, formControl);
        break;
      case 'formGroup':
        formControl = this.createFormGroup(<FormGroupDeclaration>declaration.childItem, bindData);
        formGroup.addControl(itemKey, formControl);
        break;
      default:
        let validators = FormUtil.createValidators(declaration);
        formControl = new FormControl(null, validators);
        if (bindData !== null && bindData !== undefined) {
          let value = bindData;
          if (declaration.type == 'date' || declaration.type == 'dateTime') {
            value = DateUtil.stringToDate(<string>value);
          }
          formControl.setValue(value);
        } else if (declaration.initialValue !== undefined) {
          formControl.setValue(declaration.initialValue);
        }
        formGroup.addControl(itemKey, formControl);
        break;
    }
  }
  
  public static createFormArray(declaration: FormControlDeclaration, bindData: any = undefined): FormArray {
    let validators = FormUtil.createValidators(declaration);
    let fa = new FormArray([], validators);
    let initialValue = declaration.initialValue;
    if (bindData) {
      initialValue = bindData;
    }
    if (Utils.isArrayNotEmpty(initialValue)) {
      for (let item of initialValue) {
        let f = FormUtil.createChildItem(declaration, item);
        (<FormArray>fa).push(f);
      }
    }
    return fa;
  }

  // Dùng để check childItem
  public static isFormControlDeclaration(t: any): t is FormControlDeclaration {
    return typeof (t as FormControlDeclaration)?.label == 'string';
  }

  public static createChildItem(declaration: FormControlDeclaration, bindData): FormControl|FormGroup {
    let childItem = declaration.childItem;
    let f;
    if (!childItem) {
      // Không khai báo childItem thì hiểu đấy là FormControl
      f = FormUtil.createFormControl(null, bindData);
    } else if (FormUtil.isFormControlDeclaration(childItem)) {
      f = FormUtil.createFormControl(childItem, bindData);
    } else {
      f = this.createFormGroup(childItem, bindData);
    }
    return f;
  }

  public static createFormControl(declaration: FormControlDeclaration, bindData?: any): FormControl {
    let validators = FormUtil.createValidators(declaration);
    let formControl = new FormControl(null, validators);
    if (bindData !== undefined) {
      formControl.setValue(bindData);
    }
    return formControl;
  }
  
  public static createFormGroup(declaration: FormGroupDeclaration, bindData?: any): FormGroup {
    let keys = Object.keys(declaration);
    let fg = new FormGroup({})
    for (let key of keys) {
      if (declaration[key] === undefined || declaration[key].hidden) {
        continue;
      }
      let validators = [];
      let v = declaration[key].validators;
      if (v) {
        if (Array.isArray(v)) {
          validators = <any[]>v;
        } else {
          validators.push(v);
        }
      }
      if (this.isRequired(key, declaration)) {
        validators.push(Validators.required);
      }
      let type: FormDataType = declaration[key].type || 'string';
      let formControl;
      switch (type) {
        case 'formArray':
          formControl = this.createFormArray(declaration[key], (bindData || {})[key]);
          break;
        case 'formGroup':
          formControl = this.createFormGroup(<FormGroupDeclaration>declaration[key].childItem, (bindData || {})[key]);
          break;
        case 'uploadFile':
          formControl = new FormControl('dummy', validators);
          break;
        default:
          formControl = new FormControl(null, validators);
          let initialValue = declaration[key].initialValue;
          if (bindData && bindData[key] != null) {
            initialValue = bindData[key];
          }
          if (initialValue != null) {
            this.bindDataItem(formControl, declaration[key], initialValue);
          }
          break;
      }
      fg.addControl(key, formControl);
    }
    return fg;
  }
  
  public static getFormArrayError(formArray: FormArray) {
    for (let i = 0; i < formArray.length; i++) {
      let f = formArray.at(i);
      let err;
      if (f instanceof FormArray) {
        err = this.getFormArrayError(f);
      } else if (f instanceof FormGroup) {
        err = this.getFormGroupError(f);
      } else {
        err = f.errors;
      }
      if (err) {
        return err;
      }
    }
    return null;
  }
  
  public static getFormGroupError(formGroup: FormGroup) {
    for (let key of Object.keys(formGroup.controls)) {
      let f = formGroup.get(key);
      let err;
      if (f instanceof FormArray) {
        err = this.getFormArrayError(f);
      } else if (f instanceof FormGroup) {
        err = this.getFormGroupError(f);
      } else {
        err = f.errors;
      }
      if (err) {
        return err;
      }
    }
    return null;
  }
  
  public static isFormItemChanged(f: FormControl, originalValue: any, d: FormControlDeclaration) {
    if (d.readOnly && !d.submitReadOnly) {
      // skip readOnly fields
      return false;
    }
    let formValue = this.getFormItemData(f, d);
    if (d.notAcceptNull) {
      if (formValue == null && originalValue == null) {
        return false;
      }
    }
    if (d.notAcceptEmpty) {
      let isFormValueEmpty = formValue == null || formValue.length === 0;
      let isOriginalValueEmpty = originalValue == null || originalValue.length == 0;
      if (isFormValueEmpty && isOriginalValueEmpty) {
        return false;
      }
    }
    let isChangedFn = this.isStringChanged;
    if (d.isChanged) {
      isChangedFn = d.isChanged;
    } else {
      let type = this.type(d);
      switch (type) {
        case 'date': isChangedFn = DateUtil.diffDate; break;
        case 'dateTime': return DateUtil.diff(formValue, originalValue, {skipSecond: d.skipDiffSecond});
        case 'number': isChangedFn = (n1, n2) => n1 != n2; break;
        case 'boolean': isChangedFn = (b1, b2) => !!b1 != !!b2; break;
        case 'array': isChangedFn = (a1, a2) => !Utils.isArraysTheSame(a1, a2); break;
        case 'uploadFile': break; // uploadFile is the special & rare case, specific class should provide isChanged function in its FormControlDeclaration
        default: break;
      }
    }
    return isChangedFn(formValue, originalValue);
  }
  
  public static isFormGroupChanged(formGroup: FormGroup, model: any, declaration: FormGroupDeclaration): boolean {
    let keys = Object.keys(formGroup.controls);
    let isChanged = false;
    for (let key of keys) {
      let child = model ? model[key] : undefined;
      let formControl = formGroup.get(key);
      if (formControl instanceof FormArray) {
        isChanged = this.isFormArrayChanged(formControl, child, declaration[key].childItem);
      } else if (formControl instanceof FormGroup) {
        isChanged = this.isFormGroupChanged(formControl, child, <FormGroupDeclaration>declaration[key].childItem);
      } else {
        try {
          isChanged = this.isFormItemChanged(<FormControl>formControl, child, declaration[key]);
        } catch (e) {
          console.error(e);
        }
      }
      if (isChanged) {
        return true;
      }
    }
    return false;
  }
  
  public static isFormArrayChanged(formArray: FormArray, modelArray: Array<any>, declaration: FormGroupDeclaration|FormControlDeclaration): boolean {
    let isChanged = false;
    if (modelArray && modelArray.length > formArray.length) {
      // some items are removed
      return true;
    }
    for (let i = 0; i < formArray.length; i++) {
      let form = formArray.at(i);
      let modelData = Utils.isArrayNotEmpty(modelArray) && modelArray.length >= i ? modelArray[i] : undefined;
      if (form instanceof FormArray) {
        isChanged = this.isFormArrayChanged(form, modelData, declaration);
      } else if (form instanceof FormGroup) {
        isChanged = this.isFormGroupChanged(form, modelData, <FormGroupDeclaration>declaration);
      } else {
        isChanged = this.isFormItemChanged(<FormControl>form, modelData, <FormControlDeclaration>declaration);
      }
      if (isChanged) {
        return true;
      }
    }
    return false;
  }

  // In case create new, check if user enter data or not, compare to initialValue
  public static isFormGroupHasData(formGroup: FormGroup, declaration: FormGroupDeclaration): boolean {
    if (!formGroup) return false;
    let keys = Object.keys(formGroup.controls);
    let hasData = false;
    for (let key of keys) {
      let formControl = formGroup.get(key);
      if (formControl instanceof FormArray) {
        hasData = this.isFormArrayHasData(formControl, declaration[key].childItem);
      } else if (formControl instanceof FormGroup) {
        hasData = this.isFormGroupHasData(formControl, <FormGroupDeclaration>declaration[key].childItem);
      } else {
        hasData = this.isFormItemHasData(<FormControl>formControl, declaration[key]);
      }
      if (hasData) {
        return true;
      }
    }
    return false;
  }

  public static isFormArrayHasData(formArray: FormArray, declaration: FormGroupDeclaration|FormControlDeclaration): boolean {
    if (!formArray) return false;
    let hasData = false;
    if (formArray.length == 0) {
      return false;
    }
    for (let i = 0; i < formArray.length; i++) {
      let form = formArray.at(i);
      if (form instanceof FormArray) {
        hasData = this.isFormArrayHasData(form, declaration);
      } else if (form instanceof FormGroup) {
        hasData = this.isFormGroupHasData(form, <FormGroupDeclaration>declaration);
      } else {
        hasData = this.isFormItemHasData(<FormControl>form, <FormControlDeclaration>declaration);
      }
      if (hasData) {
        return true;
      }
    }
    return false;
  }

  public static isFormItemHasData(f: FormControl, d: FormControlDeclaration): boolean {
    if (!f) return false;
    if (d.readOnly && !d.submitReadOnly) {
      // skip readOnly fields
      return false;
    }
    let formValue = this.getFormItemData(f, d);
    if (formValue == null) {
      return false;
    }
    if (d.initialValue != null && formValue == d.initialValue) {
      return false;
    }
    return true;
  }
  
  // compare single value of the same key between form vs model
  public static isStringChanged(formValue, modelValue) {
    if (formValue != modelValue) {
      if (modelValue === undefined) {
        if (formValue !== null && formValue !== undefined && formValue !== '') {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  public static isRequired(key: string, declaration: FormGroupDeclaration): boolean {
    let item = this.getItemByKey(key, declaration);
    if (!item) return false;
    return item.required;
  }
  
  public static isHidden(key: string, declaration: FormGroupDeclaration): boolean {
    let item = this.getItemByKey(key, declaration);
    if (!item) return false;
    return item.hidden;
  }
  
  public static isReadOnly(key: string, declaration: FormGroupDeclaration): boolean {
    let item = this.getItemByKey(key, declaration);
    if (!item) return false;
    return item.readOnly;
  }
  
  public static isMultiline(key: string, declaration: FormGroupDeclaration): boolean {
    let item = this.getItemByKey(key, declaration);
    if (!item) return false;
    return item.multiline;
  }
  
  public static validateFormGroup(g: FormGroup, ops?: ValidateFormOptions): ValidationErrors | null {
    let e;
    for (let key of Object.keys(g.controls)) {
      let f = g.get(key);
      if (f instanceof FormGroup) {
        e = this.validateFormGroup(f, ops);
      } else if (f instanceof FormArray) {
        e = this.validateFormArray(f, ops);
      } else {
        e = f.errors;
      }
      if (e) {
        if (ops?.markFormError) {
          f.markAsDirty();
          f.updateValueAndValidity();
        }
        return ops?.includeErrorKey ? {[key]: e} : e
      }
    }
    return null;
  }
  
  public static validateFormArray(arr: FormArray, ops?: ValidateFormOptions): ValidationErrors | null {
    let e;
    for (let i = 0; i < arr.length; i++) {
      let f = arr.at(i);
      if (f instanceof FormGroup) {
        e = this.validateFormGroup(f, ops);
      } else if (f instanceof FormArray) {
        e = this.validateFormArray(f, ops);
      } else {
        e = f.errors;
      }
      if (e) {
        if (ops?.markFormError) {
          f.markAsDirty();
          f.updateValueAndValidity();
        }
        return ops?.includeErrorKey ? {[`${i}`]: e} : e
      }
    }
    return null;
  }
  
  // Recursively
  public static setEnableFormArray(arr: FormArray, declaration: FormGroupDeclaration|FormControlDeclaration, enabled: boolean) {
    if (!arr) return;
    for (let i = 0; i < arr.controls.length; i++) {
      let form = arr.at(i);
      if (form instanceof FormArray) {
        FormUtil.setEnableFormArray(<FormArray>form, declaration, enabled);
      } else if (form instanceof FormGroup) {
        FormUtil.setEnableFormGroup(form, <FormGroupDeclaration>declaration, enabled);
      } else {
        this.setEnableFormControl(<FormControl>form, <FormControlDeclaration>declaration, enabled);
      }
    }
  }
  
  // Recursively
  public static setEnableFormGroup(group: FormGroup, declaration: FormGroupDeclaration, enabled: boolean) {
    if (!group) return;
    let keys = Object.keys(group.controls);
    for (let key of keys) {
      let form = group.controls[key];
      if (form instanceof FormArray) {
        FormUtil.setEnableFormArray(<FormArray>form, declaration[key].childItem, enabled);
      } else if (form instanceof FormGroup) {
        FormUtil.setEnableFormGroup(form, <FormGroupDeclaration>declaration[key].childItem, enabled);
      } else {
        this.setEnableFormControl(<FormControl>form, declaration[key], enabled);
      }
    }
  }

  public static setEnableFormControl(fc: FormControl, declaration: FormControlDeclaration, enabled: boolean) {
    if (!fc) return;
    if (enabled && !declaration.readOnly) {
      fc.enable();
    } else {
      fc.disable();
    }
  }
}