import { PermissionCode } from '@const/PermissionCode';
import { Utils } from './utils';

export class PermissionManager {
  public static userHasNoPermission(user): boolean {
    return !user || !Utils.isArrayNotEmpty(user.permissions);
  }

  public static userHasPermission(user, permissionCode: string): boolean {
    if (PermissionManager.userHasNoPermission(user)) {
      return false;
    }
    for (let permission of user.permissions) {
      if (permission === permissionCode) {
        return true;
      }
    }
    return false;
  }

  public static userHasOneOfPermissions(user, permissions: Array<string>): boolean {
    if (PermissionManager.userHasNoPermission(user)) {
      return false;
    }
    for (let permission of user.permissions) {
      if (permissions.includes(permission)) {
        return true;
      }
    }
    return false;
  }

  // Chỉ có quyền login.work mới đc login vào sử dụng web
  public static allowLogin(user): boolean {
    if (PermissionManager.userHasNoPermission(user)) {
      return false;
    }
    for (let permission of user.permissions) {
      if (permission === PermissionCode.login.work) {
        return true;
      }
    }
    return false;
  }

}
