import { AppConst } from "@app/const.generated";
import { WorkTaskStatus } from "@wearewarp/types";

export class Const {

  // Route
  public static readonly routeLogin = `/login`;
  public static readonly routeVerifyLogin = `/verify`;
  public static readonly routeDashboard = '/dashboard';

  // API URI backend origin
  public static readonly APIURI_TIMEOUT_TEST = 'http://google.com:81';
  public static readonly API_SEARCH_US_CITIES = 'https://gw.wearewarp.com/us-cities/v1/search/city';
  public static readonly APIURI_MY_PROFILE = Const.API_ME('my_profile');
  public static readonly APIURI_TASKS = 'tasks';
  public static readonly APIURI_JOBS = 'jobs';
  public static readonly APIURI_USERS = 'users';
  public static readonly APIURI_CONVERSATIONS = 'v2/conversations';
  public static readonly APIURI_DRIVERS = 'drivers';
  public static readonly APIURI_CARRIERS = 'carriers';
  public static readonly APIURI_WORK_QUEUE = Const.APIV2('work_queue');
  
  // API backend work queue
  public static readonly API_FETCH_TASK = 'v1/w/d/tasks/get-queue-task';
  public static API_COMPLETE_TASK(taskId: string): string { return `v1/w/d/tasks/${taskId}/complete-task` };
  public static API_GET_SPECIFIC_TASK(taskId: string): string { return `v1/w/d/tasks/${taskId}` };
  public static API_COMPLETE_SPECIFIC_TASK(taskId: string): string { return `v1/w/d/tasks/${taskId}/complete-specific-task` };
  public static API_AUTO_COMPLETE_TASK(taskId: string): string { return `v1/w/d/tasks/${taskId}/auto-complete-task` };
  public static API_GET_LOG_HISTORY(taskId: string): string { return `v1/w/d/tasks/${taskId}/get-histories`};
  public static API_UNASSIGN_TASK(taskId: string): string { return `v1/w/d/tasks/${taskId}/un-assign-task` };
  public static API_PAUSE_TASK(taskId: string): string { return `v1/w/d/tasks/${taskId}/pause-task` };
  public static API_UPDATE_LOG_HISTORY(taskId: string): string { return `v1/w/d/tasks/${taskId}/update-history` };


  public static APIV2(path: string): string { return `v2/${path}` };
  public static API_SESSION(): string { return 'ss?e=1' };
  public static API_ME(action: string = null): string { return action ? `me/${action}` : 'me' }
  public static API_PUBLIC(action: string): string { return `p/${action}` };

  public static MAPBOX_STYLE(name: string): string {
    return `mapbox://styles/mapbox/${name}`;
  }

  public static readonly DATETIME_FORMAT_FROM_DB = 'YYYY-MM-DD HH:mm:ss';
  public static readonly FORMAT_GUI_DATETIME_SHORT = 'M/D/YY, h:mm a';
  public static readonly FORMAT_GUI_DATE = 'MMM D, YYYY';
  public static readonly FORMAT_GUI_DATETIME = 'MMM D, YYYY, h:mm a';
  public static readonly MOBILE_WIDTH = 568;

  public static readonly ENCRYPT_KEY = 'y1pSrKRLLkCQGbt0rqA5DJh3XnjDG3j9';
  public static readonly ENCRYPT_IV = 'U0mkzdFK7naqEn60';

  public static readonly WORK_DISPATCHER_APP_ID: number = 13;
  public static readonly APP_HTTP_HEADER: string = `${Const.WORK_DISPATCHER_APP_ID};${AppConst.Version};${AppConst.BuildWhen.replace(/[\/\s:]/g, '')}`;

  public static readonly TrackingTask = {
    arrivalConfirmation: 'arrivalConfirmation',
    departureConfirmation: 'departureConfirmation',
    routeConfirmation: 'routeConfirmation',
    otpConfirmation: 'otpConfirmation',
    etaConfirmation: 'etaConfirmation',
  }

  public static get TrackingTaskLabel() {
    return {
      arrivalConfirmation: 'Confirm Arrival',
      departureConfirmation: 'Confirm Departure',
      routeConfirmation: 'Confirm Booking',
      otpConfirmation: '24 hour check',
      etaConfirmation: 'Confirm ETA',
    }
  }

  public static get WorkTaskStatus(): {[key in WorkTaskStatus]: WorkTaskStatus} {
    return {
      onHold: 'onHold',
      pending: 'pending',
      inProcess: 'inProcess',
      paused: 'paused',
      completed: 'completed',
      escalated: 'escalated'
    }
  }

  public static readonly JobStatus = {
    created: "created",
    inProgress: "inProgress",
    canceled: "canceled",
    completed: "completed",
  }

  public static getJobStatusText(status) {
    if (!status) {
      return 'Created';
    }
    switch (status) {
      case this.JobStatus.created: return  'Created';
      case this.JobStatus.inProgress: return 'In Progress';
      case this.JobStatus.completed: return 'Completed';
      case this.JobStatus.canceled: return 'Canceled';
      default: return status;
    }
  }

  public static readonly StopStatus = {
    created: "created",
    enroute: "enroute",
    arrived: "arrived",
    succeeded: "succeeded",
    failed: "failed",
    canceled: "canceled",
    pickupFailed: "pickupFailed"
  }

  public static readonly RouteTaskStatus = {
    created: "created",
    enroute: "enroute",
    arrived: "arrived",
    succeeded: "succeeded",
    failed: "failed",
    canceled: "canceled",
    pickupFailed: "pickupFailed"
  }

  public static getStopStatusText(status) {
    switch (status) {
      case this.StopStatus.created: return  'Created';
      case this.StopStatus.enroute: return 'En-route';
      case this.StopStatus.arrived: return 'Arrived';
      case this.StopStatus.succeeded: return 'Succeeded';
      case this.StopStatus.failed: return 'Failed';
      case this.StopStatus.canceled: return 'Canceled';
      case this.StopStatus.pickupFailed: return 'Pickup Failed';
      default: return status;
    }
  }

  public static getTaskTypeText(status) {
    switch(status) {
      case "PICKUP": return "Pickup";
      case "DROPOFF": return "Dropoff";
      case "TRANSIT": return "Transit";
      case "RETURN": return "Return";
      case "RETURN_DEPOT": return "Return Depot";
      default: return status;
    }
  }

  public static readonly ShipmentType = {
    FTL: "FTL",
    LTL: "LTL",
    PARCEL: "PARCEL"
  }

  public static readonly RouteTaskType = {
    PICKUP: 'PICKUP',
    DROPOFF: 'DROPOFF'
  }

  public static readonly UpdateByCollection = {
    users: 'users',
  }

  public static readonly DriverStatus = {
    active: 1
  }
}
